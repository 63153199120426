import { useWeb3React } from '@telekomconsalting/core'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAuth } from '../../containers/AuthWrapper'
import noop from '../../helpers/noop'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { State } from '../../reducers'
import chatWidgetReducer, { initChatState, setIsInitialized } from './chatWidgetReducer'
import {
  closeChat,
  hideChatWidget,
  init,
  logIn,
  logOut,
  openChat,
  showChatWidget,
  updateUser,
} from './chatWidgetService'

export const useChat = (): ChatWidgetState => useContext(ChatWidgetContext)

export interface ChatWidgetState {
  openChat: (isInitialized: boolean) => void
  closeChat: (isInitialized: boolean) => void
  showChatWidget: (isInitialized: boolean) => void
  hideChatWidget: (isInitialized: boolean) => void
  isInitialized: boolean
}

export const ChatWidgetContext = React.createContext<ChatWidgetState>({
  openChat: noop,
  closeChat: noop,
  showChatWidget: noop,
  hideChatWidget: noop,
  isInitialized: false,
})

const ChatWidgetProvider: React.FC = ({ children }) => {
  const { isAuth, accountId } = useAuth()
  const [isMounted, setIsMounted] = useState(false)
  const isMobile = useSelector((state: State) => state.isMobile)
  const { walletCategory } = useSelector((state: State) => state.user)
  const { account } = useWeb3React()

  const [state, dispatch] = useReducer(chatWidgetReducer, initChatState)

  const mountHelpCrunch = async (): Promise<void> => {
    const doMount = (w: Window, d: Document): void => {
      w.HelpCrunch = function (): void {
        // Let HC do the nasty trick of using the same global variable as arguments and function
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        w.HelpCrunch.q.push(arguments)
      } as () => void

      // Let HC do the nasty trick of using the same global variable as arguments and function
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      w.HelpCrunch.q = []
      function loadScript(): void {
        const s = document.createElement('script')
        s.async = true
        s.type = 'text/javascript'
        s.src = 'https://widget.helpcrunch.com/'
        d.body.appendChild(s)
        s.onload = (): void => setIsMounted(true)
      }

      loadScript()
    }

    doMount(window, document)
  }

  const shouldMount = (): boolean =>
    !state.isInitialized &&
    process.env.REACT_APP_CHAT_ENABLED === 'true' &&
    !!process.env.REACT_APP_HELP_CRUNCH_SECRET

  useComponentDidMount(() => {
    if (shouldMount()) {
      mountHelpCrunch()
    }
  })

  useEffect(() => {
    const initHelpCrunch = (accountId: string): void => {
      // Let HC do the nasty trick of using the same global variable as arguments and function
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.HelpCrunch()

      init(dispatch, accountId, state.isInitialized)
      dispatch(setIsInitialized(true))
    }

    if (
      !isMobile &&
      isMobile !== null &&
      !state.isInitialized &&
      process.env.REACT_APP_CHAT_ENABLED === 'true' &&
      !!process.env.REACT_APP_HELP_CRUNCH_SECRET &&
      !!accountId &&
      isAuth
    ) {
      initHelpCrunch(accountId)
    }

    if (
      state.isInitialized &&
      accountId &&
      isAuth &&
      process.env.REACT_APP_CHAT_IN_ROUTES !== 'true'
    ) {
      showChatWidget(state.isInitialized)
    }

    if (state.isInitialized && !accountId && !isAuth) {
      logOut(dispatch, state.isInitialized)
    }

    if (state.isInitialized && state.isLoggedOut && isAuth && accountId) {
      logIn(dispatch, accountId, state.isInitialized)
    }
  }, [
    account,
    accountId,
    isAuth,
    isMounted,
    state.isLoggedOut,
    isMobile,
    state.isInitialized,
    walletCategory,
  ])

  useEffect(() => {
    if (walletCategory && accountId) {
      updateUser(accountId, account, walletCategory, state.isInitialized)
    }
  }, [walletCategory, account, state.isInitialized, accountId])

  return (
    <ChatWidgetContext.Provider
      value={{
        openChat,
        closeChat,
        isInitialized: state.isInitialized,
        showChatWidget,
        hideChatWidget,
      }}>
      {children}
    </ChatWidgetContext.Provider>
  )
}

export default ChatWidgetProvider
