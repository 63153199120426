enum ChatWidgetActions {
  init = 'init',
  loggedOut = 'loggedOut',
}

type InitChatWidgetAction = {
  type: ChatWidgetActions.init
  payload: boolean
}

type SetChatWidgetLoggedOutAction = {
  type: ChatWidgetActions.loggedOut
  payload: boolean
}

export type ChatWidgetAction = InitChatWidgetAction | SetChatWidgetLoggedOutAction

export type ChatWidgetState = {
  isInitialized: boolean
  isLoggedOut: boolean
}

export const setIsInitialized = (payload: boolean): InitChatWidgetAction => ({
  type: ChatWidgetActions.init,
  payload,
})

export const setLoggedOut = (payload: boolean): SetChatWidgetLoggedOutAction => ({
  type: ChatWidgetActions.loggedOut,
  payload,
})

export const initChatState: ChatWidgetState = {
  isInitialized: false,
  isLoggedOut: false,
}

const chatWidgetReducer = (state: ChatWidgetState, action: ChatWidgetAction): ChatWidgetState => {
  switch (action.type) {
    case ChatWidgetActions.init:
      return { ...state, isInitialized: true }
    case ChatWidgetActions.loggedOut:
      return { ...state, isLoggedOut: true }
    default:
      return state
  }
}

export default chatWidgetReducer
