export enum ChatAction {
  // more here https://docs.helpcrunch.com/developers/methods
  Init = 'init',
  OpenChat = 'openChat',
  CloseChat = 'closeChat',
  ShowChatWidget = 'showChatWidget',
  HideChatWidget = 'hideChatWidget',
  UpdateUser = 'updateUser',
  Logout = 'logout',
  UserAuth = 'userAuth',
}
