import { auth } from '@telekomconsalting/dex-guru-internal-sdk'
import React, { FC, Suspense, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { setWalletAddress } from '../../actions'
import { setOmniboxSearchOpen } from '../../actions/uiActions'
import { environment } from '../../config/settings'
import { useAuth } from '../../containers/AuthWrapper'
import { getRootElement } from '../../helpers/appHelpers'
import { getSessionHeader } from '../../helpers/ProxyApiFetch/sessionManager'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { StatefulTransaction } from '../../model'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import TxnCompleteModal from '../TxnModals/TxnCompleteModal'
import ContentDesktopMain from './ContentDesktopMain'

const ProviderMenu = React.lazy(() => import(/* webpackPrefetch: true */ '../ProviderMenuWrapper'))

const ContentDesktopWithModals: FC = () => {
  const reduxDispatch = useDispatch()
  const { closeProviderMenu, isAuth } = useAuth()
  const { isProviderMenuOpen } = useSelector((state: State) => state.ui)
  const networksConfig = useSelector((state: State) => state.networksConfig.data)
  const buyTrade = useSelector((state: State) => state.txn.Buy)
  const sellTrade = useSelector((state: State) => state.txn.Sell)
  const [successfulTrade, setSuccessfulTrade] = useState<StatefulTransaction>()
  const [failedTrade, setFailedTrade] = useState<StatefulTransaction>()

  useEffect(() => {
    if (buyTrade.txnVerificationSuccess) {
      setSuccessfulTrade(buyTrade)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21250
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyTrade.txnVerificationSuccess])

  useEffect(() => {
    if (sellTrade.txnVerificationSuccess) {
      setSuccessfulTrade(sellTrade)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21250
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellTrade.txnVerificationSuccess])

  useEffect(() => {
    if (buyTrade.isPostSubmissionFailure) {
      setFailedTrade(buyTrade)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21250
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyTrade.isPostSubmissionFailure])

  useEffect(() => {
    if (sellTrade.isPostSubmissionFailure) {
      setFailedTrade(sellTrade)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21250
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellTrade.isPostSubmissionFailure])

  useComponentDidMount(() => {
    Modal.setAppElement('body')
  })

  const handleTooltipShow = (e: React.MouseEvent): void => {
    const el = e.target as HTMLElement
    if (!el) {
      return
    }

    const trackData = el.dataset.trackInfo
    if (trackData) {
      const trackDataObj = JSON.parse(trackData)
      if (trackDataObj) {
        amplitudeService.sendEvent(trackDataObj.type, trackDataObj.data)
      }
    }
  }

  const onDisconnect = async (account: string, currentProviderName: string): Promise<void> => {
    reduxDispatch(setWalletAddress(undefined, undefined))
    amplitudeService.walletDisconnected({
      wallet_address: account,
      wallet_provider: currentProviderName,
    })
    auth?.dropAuthentication(environment.getDexGuruAuthApiV2Url(), getSessionHeader())
  }

  return (
    <React.Fragment>
      <ContentDesktopMain />
      <ReactTooltip
        id="app-tooltip"
        globalEventOff="click"
        className=""
        effect="solid"
        html={true}
        type="light"
        afterShow={handleTooltipShow}
      />

      <Modal
        parentSelector={getRootElement}
        isOpen={isProviderMenuOpen}
        onRequestClose={(): void => {
          reduxDispatch(setOmniboxSearchOpen(false, false))
          closeProviderMenu()
        }}
        overlayClassName="modal-overlay"
        className="modal-content">
        {isProviderMenuOpen && (
          <Suspense fallback={<div />}>
            <ProviderMenu
              networksConfig={networksConfig}
              closeAfterSelect={false}
              isMobile={false}
              onDisconnect={onDisconnect}
            />
          </Suspense>
        )}
      </Modal>

      <Suspense fallback={<div />}>
        {successfulTrade && (
          <TxnCompleteModal
            isOpen={isAuth && !!successfulTrade}
            onClose={(): void => setSuccessfulTrade(undefined)}
            trade={successfulTrade}
            type="success"
          />
        )}
      </Suspense>

      <Suspense fallback={<div />}>
        {failedTrade && (
          <TxnCompleteModal
            isOpen={isAuth && !!failedTrade}
            onClose={(): void => setFailedTrade(undefined)}
            trade={failedTrade}
            type="failed"
          />
        )}
      </Suspense>
    </React.Fragment>
  )
}

export default ContentDesktopWithModals
