import { WalletConnectors } from '@telekomconsalting/react-dexguru-wallet'
import React, { useContext } from 'react'

import noop from '../helpers/noop'
import { NetworkConfigV3 } from '../model'

interface ConnectorsContextState {
  walletConnectors: WalletConnectors | undefined
  initWalletConnectors: (networkConfigs: NetworkConfigV3[]) => void
}
const ConnectorsContext = React.createContext<ConnectorsContextState>({
  walletConnectors: undefined,
  initWalletConnectors: noop,
})

export const useConnectors = (): ConnectorsContextState => useContext(ConnectorsContext)

export const ConnectorsProvider: React.FC = ({ children }) => {
  const [walletConnectors, setWalletConnectors] = React.useState<WalletConnectors | undefined>(
    undefined
  )

  const initWalletConnectors = (networkConfigs: NetworkConfigV3[]): void => {
    if (!walletConnectors) {
      setWalletConnectors(new WalletConnectors(networkConfigs))
    }
  }

  return (
    <ConnectorsContext.Provider
      value={{ initWalletConnectors: initWalletConnectors, walletConnectors: walletConnectors }}>
      {children}
    </ConnectorsContext.Provider>
  )
}
