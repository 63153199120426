import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { State } from '../../reducers'
import ContentDesktop from './ContentDesktopWithModals'

const ContentMobile = React.lazy(() => import(/* webpackPrefetch: true */ './ContentMobile'))

const Content = (): React.ReactElement => {
  const { isMobile } = useSelector((state: State) => state)

  useComponentDidMount(() => {
    const observer = new MutationObserver(() => {
      ReactTooltip.rebuild()
    })
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
    return (): void => {
      observer.disconnect()
    }
  })

  if (isMobile) {
    return (
      <Suspense fallback={<div />}>
        <ContentMobile />
      </Suspense>
    )
  }

  return <ContentDesktop />
}

export default Content
