import { useWeb3React } from '@telekomconsalting/core'
import { History, Location } from 'history'
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAuth } from '../../containers/AuthWrapper'
import { State } from '../../reducers'
import { buildHistoryRoute, buildLiquidityRoute, buildTokenProfileRoute } from '../../routes'
import { ChatWidgetContext, ChatWidgetState } from './ChatWidgetProvider'

export const useChat = (): ChatWidgetState => useContext(ChatWidgetContext)

export const useRouteChange = (history: History): void => {
  const { isInitialized, hideChatWidget, showChatWidget } = useContext(ChatWidgetContext)
  const { isAuth } = useAuth()
  const { currentToken } = useSelector((state: State) => state.tokens)
  const { walletCategory } = useSelector((state: State) => state.user)
  const isMobile = useSelector((state: State) => state.isMobile)
  const { account } = useWeb3React()

  useEffect((): void => {
    const init = async (): Promise<void> => {
      if (!isInitialized) {
        return
      }

      if (isInitialized) {
        hideChatWidget(isInitialized)
      }

      if (shouldShowChat(isAuth, isMobile, history.location, currentToken?.id)) {
        showChatWidget(isInitialized)
      }
    }

    init()
  }, [
    isMobile,
    history.location,
    currentToken?.id,
    isAuth,
    hideChatWidget,
    isInitialized,
    showChatWidget,
    account,
    walletCategory,
  ])
}

const shouldOpenChatForLocation = (location: Location, id = ''): boolean =>
  location.pathname === buildTokenProfileRoute(id) ||
  location.pathname === buildLiquidityRoute(id) ||
  location.pathname === buildHistoryRoute(id) ||
  location.pathname.startsWith('/trader')

const shouldShowChat = (
  isAuth: boolean,
  isMobile: boolean | null,
  location: Location,
  tokenId?: string
): boolean => {
  if (!isAuth || isMobile) {
    return false
  }

  if (process.env.REACT_APP_CHAT_IN_ROUTES === 'true') {
    return shouldOpenChatForLocation(location, tokenId)
  } else {
    return true
  }
}
