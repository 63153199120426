import { useWeb3React } from '@telekomconsalting/core'
import { auth } from '@telekomconsalting/dex-guru-internal-sdk'
import React, { FC, useEffect } from 'react'
import Modal from 'react-modal'

import { appGitVersion, environment } from '../../config/settings'
import { useAuth } from '../../containers/AuthWrapper'
import { getSessionHeader } from '../../helpers/ProxyApiFetch/sessionManager'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { useConnectors } from '../ConnectorsContext'
import { LayoutDesktop } from '../Layout/LayoutDesktop'

export const version = `Beta ${appGitVersion}`

const ContentDesktopMain: FC = () => {
  const { connector, deactivate } = useWeb3React()
  const { walletConnectors } = useConnectors()

  const { forceDisconnect } = useAuth()

  useComponentDidMount(() => {
    Modal.setAppElement('body')
  })

  useEffect(() => {
    if (forceDisconnect && walletConnectors) {
      auth?.dropAuthentication(environment.getDexGuruAPIV2Url(), getSessionHeader()).then(() => {
        walletConnectors?.disconnect(connector, deactivate)
      })
    }
  }, [walletConnectors, forceDisconnect, connector, deactivate])

  return <LayoutDesktop />
}

export default ContentDesktopMain
