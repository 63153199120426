import { Dispatch } from 'react'

import { UserWalletCategory } from '../../model'
import { ChatWidgetAction, setLoggedOut } from './chatWidgetReducer'
import { ChatAction } from './types'

export const logOut = (dispatch: Dispatch<ChatWidgetAction>, isInitialized: boolean): void => {
  closeChat(isInitialized)
  triggerAction(ChatAction.HideChatWidget, isInitialized)
  triggerAction(ChatAction.Logout, isInitialized)
  dispatch(setLoggedOut(true))
}

export const showChatWidget = (isInitialized: boolean): void => {
  triggerAction(ChatAction.ShowChatWidget, isInitialized)
}

export const hideChatWidget = (isInitialized: boolean): void => {
  triggerAction(ChatAction.HideChatWidget, isInitialized)
}

export const openChat = (isInitialized: boolean): void => {
  triggerAction(ChatAction.OpenChat, isInitialized)
}

export const closeChat = (isInitialized: boolean): void => {
  triggerAction(ChatAction.CloseChat, isInitialized)
}

export const logIn = (
  dispatch: Dispatch<ChatWidgetAction>,
  accountId: string,
  isInitialized: boolean
): void => {
  triggerAction(
    ChatAction.UserAuth,
    isInitialized,
    true,
    // sadly while email is not required in init, it is for auth.
    // see https://docs.helpcrunch.com/developers/methods for reference
    { user_id: accountId, email: `${accountId}@wallet.dexguru` },
    () => {
      dispatch(setLoggedOut(false))
      showChatWidget(isInitialized)
    }
  )
}

export const init = (
  dispatch: Dispatch<ChatWidgetAction>,
  accountId: string,
  isInitialized: boolean
): void => {
  triggerAction(ChatAction.Init, isInitialized, false, 'dexguru', {
    applicationId: 1,
    applicationSecret: process.env.REACT_APP_HELP_CRUNCH_SECRET,
    user: {
      user_id: accountId,
      email: `${accountId}@wallet.dexguru`,
    },
  })
}

export const updateUser = (
  accountId: string,
  account: string | null | undefined,
  walletCategory: UserWalletCategory,
  isInitialized: boolean
): void => {
  triggerAction(ChatAction.UpdateUser, isInitialized, undefined, {
    user: {
      user_id: accountId,
      email: `${accountId}@wallet.dexguru`,
      custom_data: {
        wallet_category: walletCategory,
        wallet_id: account,
      },
    },
  })
}

const triggerAction = (
  action: ChatAction,
  isInitialized: boolean,
  requiresInit = true,
  value?: string | unknown,
  payload?: unknown,
  callback?: () => void
): void => {
  if (HelpCrunch && (!requiresInit || isInitialized)) {
    if (value) {
      HelpCrunch(action.toString(), value, payload || callback)
    } else {
      HelpCrunch(action.toString(), payload || callback)
    }
  }
}
